.about__description{
text-align: center;
max-width:100%;
margin:2rem auto 0 auto;
font-size: 1rem;
font-weight: 100;
}
.about__signature {
display: block;
width: 100%;
height: 120px;
text-align: center;
margin-top: 1rem;
}
.about__signature .mysignature{
  width: 200px;
  fill: var(--light-text-color);
}

.cv__container {
text-align: center;
margin-top: 2rem;
}

/* For medium devices */
@media screen and (min-width: 992px) {
  .about__description{
  text-align: left;
  }
  .cv__container {
 text-align: left;
  }
  .about__signature {
  text-align: left;
  }
}