.qualification{
position: relative;
overflow: hidden;
}

.qualification::before {
content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 1100px;
background-image: url(../../assets/dots.png);
background-position: center bottom;
background-size: cover;
opacity: 0.3;
z-index: -1;
}

.qualification__tabs {
display: flex;
justify-content: flex-start;
margin-bottom: var(--mb-2);
}
.qualification__button {
font-family: var(--heading-font);
font-size: var(--h3-font-size);
font-weight: var(--font-medium);
color: var(--title-color);
background-color: #e8effe;
margin: 0 var(--mb-1) 0 0;
padding:6px 15px;
border-radius: 50px;
cursor: pointer;
transition: 300ms ease all;
}
.qualification__button:hover{
color: white;
background-color: var(--accent);
}
.qualification__icon {
font-size: 1.2rem;
margin-right: var(--mb-0-25);
}
.qualification__active {
color: white;
background-color: var(--accent-dark);
}
.qualification__sections {
display: grid;
grid-template-columns: initial;
justify-content: flex-start;
}
.qualification__content {
display:none;
}
.qualification__content-active {
display: block;
}
.qualification__data{
display: grid;
grid-template-columns: 1fr max-content 1fr;
column-gap: 0.8rem;
}
.qualification__title {
font-size: calc(var(--normal-font-size) - 2px);
font-weight: var(--font-medium);
}
.qualification__subtitle{
display: inline-block;
font-size: var(--small-font-size);
margin-bottom: var(--mb-1);
}
.qualification__calender {
font-size: var(--small-font-size);
}
.uil-calendar-alt,
 .date {
color:var(--main);
}
.qualification__rounder {
display: inline-block;
width: 13px;
height: 13px;
background-color:var(--accent);
border-radius: 50%;
}
.qualification__line {
display: block;
width: 1px;
height: 100%;
background-color: var(--accent);
transform: translate(6px, -7px);
}


@media screen and (min-width: 576px ){
  .qualification__container  {
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
  }
  .qualification__sections {
    grid-template-columns: 0.5fr;
  }
}

@media screen and (min-width: 992px ){
  .qualification__container  {
      margin-left: auto;
      margin-right: auto;
  }
.qualification__button {
  font-size: var(--h2-font-size);
  margin: 0 var(--mb-1) 0 0;
  padding:6px 30px;

  }
  .qualification__icon {
  font-size: 2rem;
  margin-right: var(--mb-0-25);
  }
  .qualification__data{
  column-gap: 2rem;
  }
  .qualification__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  }
}