.work__filters {
display: flex;
justify-content: center;
align-items: center;
column-gap: 0.75rem;
margin-bottom: var(--mb-2);
border-bottom: 1px solid rgba(0,0,0,0.1);
}
.work__item {
text-transform: uppercase;
font-family: var(--heading-font);
font-size: 1.2rem;
font-weight: var(--font-large);
color: var(--main);
padding: 0.45rem;
border-bottom: 1px solid transparent;
transition: var(--transition);
cursor: pointer;
}
.work__item:hover {
border-bottom: 1px solid var(--title-color);
}
.work__item-active {
border-bottom: 1px solid var(--accent);
}
.work__container {
grid-template-columns: 1fr;
gap: 1rem;
justify-content: center;
}
.work__card {
background-color: var(--container-color);
border:1px solid rgba(0,0,0,0.3);
border-radius: 1rem;
padding:0;
overflow: hidden;
}
.work__inner{
padding:0 0.8rem 0.8rem 0.8rem;
}
.work__img {
position: relative;
width: 100%;
margin-bottom: .4rem;
}

.work__title {
font-size: var(--normal-font-size);
font-weight:var(--font-medium);
margin-bottom: var(--mb-0-5);
color: #454545;
}
.work__description {
font-size: var(--small-font-size);
font-weight:var(--font-medium);
margin-bottom: var(--mb-0-5);
}
.work__button {
color: var(--accent) !important;
font-size: var(--normal-font-size);
display: flex;
align-items: center;
column-gap: 0.25rem;
cursor: pointer !important;
all: unset;

}
.work__button-icon {
font-size: 1rem;
transition: 0.4s;
}
.work__button:hover .work__button-icon {
transform: translateX(0.45rem);
}
.modal{
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: var(--z-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 1;
  visibility: visible;
  transition: .3s;
  overflow-y: scroll;
}
.modal__content{
  width: 100%;
  background: white;
  padding:1.2rem;
  border-radius: 25px;

}

.modal__img{
  margin-top: 1rem;
}
.modal__close{
  display: block;
  font-size: 2rem;
  width: 2.2rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
@media screen and (min-width: 992px ){
  .work__filters {
  justify-content: flex-start;
  }
  .work__container {
    grid-template-columns: repeat(4, 1fr);
    }
    .modal__content{
      padding: 3rem;
    }
}

/* @media screen and (max-width: 576px ){
  .work__container {
  grid-template-columns: repeat(2, 1fr);
  }
  } */
/* @media screen and (min-width: 576px ){
.work__container {
grid-template-columns: repeat(2, 1fr);
}
} */

