.scrollup {
display: none;
position: fixed;
right: 2.5rem;
bottom: -50%;
opacity: 0.8;
padding: 0.5rem 0.8rem;
background-color: var(--main);
border-radius: 0.4rem;
z-index: var(--z-tooltip);
transition: .4s;
}

.scrollup__icon {
font-size: 1.5rem;
color: var(--container-color);
}
.scrollup:hover{
background-color: var(--accent-dark);
}

.show-scroll {
bottom: 3rem;
}


@media screen and (min-width: 769px ){
  .scrollup {
   display: block;
  }
}