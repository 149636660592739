.testimonial__card {
background-color: var(--container-color);
border: 1px solid rgba(0, 0, 0, 0.1);
margin-bottom: var(--mb-3);
margin-right: 2rem;
text-align: center;
}
.testimonial__card::before{
content: '\201C';
color:#fff;
font-size: 6rem;
line-height: 5.5rem;
width: 70px;
height: 40px;
background-color: var(--accent);
position: absolute;
top: 0;
right: 0;
display: flex;
justify-content: center;
font-family: var(--body-font);
}
.testimonial__inner{
  padding:1.5rem 2rem 0.5rem 2rem;
}
.testimonial__image {
width: 120px;
border-radius: 60px;
margin-bottom: var(--mb-1);
}
.testimonial__name {
font-size: 1.2rem;
font-weight: var(--font-medium);
margin-top: 0;
font-style: italic;

}
.testimonial__description {
position: relative;
font-size: var(--small-font-size);
font-weight: 300;
isolation: isolate;
z-index: 0;
}
.testimonial__content {
  margin-top: var(--mb-0-25);
  color: rgba(0,0,0,0.4);
  font-size: 0.755rem;
  padding:1rem 2rem;
  background-color: var(--body-color);
}
.jobtitle {
color: var(--accent);
font-size: 0.855rem;
}

/* swiper classes overwrite */
.swiper-pagination-bullet {
background-color: var(--accent-dark) !important;
width: 32px !important;
height: 3px !important;
border-radius: 22px !important;
}
.swiper-pagination-bullet-active {
background-color: var(--accent-dark) !important;
width: 32px !important;
border-radius: 22px !important;
}




@media screen and (min-width: 992px ){
  .testimonial__card {
  text-align: left;
  }
}