.home {
position: relative;
background: var(--linear-gradient);
height: 750px;
z-index: 0;
overflow: hidden;
}
.home .for__canvas {
position: relative;
}
.home__content .button {
position: absolute;
top: 25rem;
left: 50%;
transform: translateX(-50%);
background-color: var(--main);
width: 190px;
transition: 300ms ease-in all;
}
.home__content .button:hover{
background-color: var(--accent-dark);
}
.homer__content .button:focus,
.home__content .button:hover{
box-shadow:6px 6px 0px  var(--accent);
}
.home__social {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.home__social-icon{
font-size: 1.25rem;
color: var(--white-text);
transition: var(--transitions);
cursor: pointer;
}
.home__social-icon:hover{
color: var(--main);
}
.home .home__title {
position: absolute;
left: 50%;
transform: translateX(-50%);
top:10rem;
width: 90%;
font-size: 2rem;
margin-bottom: var(--mb-0-25);
color: var(--white-text);
line-height: 1.2;
letter-spacing: -6px;
display: flex;
justify-content: center;
align-items: center;
gap: 18px;
}
.home .hammer_rench {
  margin-top: -12px;
  width: 35px;
  height: 35px;
}

.home__subtitle{
position: absolute;
top: 15rem;
left: 50%;
transform: translateX(-50%);
font-size: var(--h3-font-size);
font-weight: var(--font-normal);
margin-bottom: var(--mb-1);
color: var(--white-text);
}
.home__subtitle::before{
content: '';
position: absolute;
width: 70px;
height: 2px;
opacity: 0;
left: 0;
top: 1rem;
}
.home__description{
position: absolute;
top: 18rem;
left: 50%;
transform: translateX(-50%);
width: 90%;
font-size: 1.2rem;
color: var(--white-text);
text-align: center;
}

.home__scroll {
position: absolute;
top: 32rem;
left: 50%;
transform: translateX(-50%);
}

.wheel {
  animation: scroll 2s ease infinite
}

@keyframes scroll {
  0% { 
      transform: translateY(0);
      }
  30%{
      transform: translateY(3.75rem);
  }
}
.home__scroll-name{
color: var(--white-text);
font-weight: var(--font-medium);
margin-right: var(--mb-0-25);
margin-left: 2px;
}
.home__scroll-arrow{
color: var(--white-text);
font-size: 1.25rem;
}



@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* For medium devices */
@media screen and (min-width: 992px) {
  .home__content .button {
    top: 28rem;
    left: 0;
    transform: translateX(0);
    width: 220px;
    }

  .home__social {
  top: 17rem;
  right: 1rem;
  transform: translateX(0);
  align-items: flex-end;
  flex-direction: column;

  }
  .home .home__title {
  left: 0;
  transform: translateX(0);
  width:fit-content;
  top:9rem;
  font-size: 4rem;
  }
  .home .hammer_rench {
    margin-top: -12px;
    width: 65px;
    height: 65px;
  }
  .home__subtitle{
  top: 15rem;
  padding-left: 5rem;
  left: 0;
  transform: translateX(0);  
  }
  .home__subtitle::before{
  content: '';
  position: absolute;
  width: 70px;
  height: 2px;
  background-color: var(--white-text);
  left: 0;
  top: 1rem;
  opacity: 1;
  }
  .home__description{
  top: 21rem;
  left: 0;
  transform: translateX(0);
  width: fit-content;
  max-width: 600px;
  text-align: left;
  }


  .home__scroll {
  top: 36rem;
  }
}