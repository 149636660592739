/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');


/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 38%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --light-text-color: hsl(var(--hue), var(--sat), 66%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: hsl(var(--hue), var(--sat), 100%);
  --white-text: hsl(var(--hue), var(--sat), 100%);
  --accent: #3c3ff4;
  --accent-dark: #3134b0;
  --accent-light: #a0a1eb;
  --radial-gradient: radial-gradient(circle, rgb(100, 100, 100) 0%, rgb(5, 5, 5) 94%);
  --radial-contrast: radial-gradient(circle, rgb(216, 216, 216) 0%, rgb(5, 5, 5) 94%);  
  --linear-gradient: linear-gradient(180deg, #5f50e6 0%, #3134b0 80%); 

  --main: #3134b0;


/*========== Font and typography ==========*/
  --heading-font: 'Quattrocento', sans-serif;
  --body-font: 'Nunito Sans', sans-serif;

  /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.789rem;
  --tiny-font-size: 0.625rem;

  /*========== Font weight ==========*/
  --font-normal: 200;
  --font-medium: 300;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
  /*======== transition ======*/
  --transitions: .3s;
  --transitions-head: .6s;
}

/* Responsive typography */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.25rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*=============== BASE ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  font-family: var(--heading-font);
  color: var(--main);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 3rem 0 4rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--main);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 2rem;
}

.section__title,
.section__subtitle {
  text-align: left;
}

/*=============== LAYOUT ===============*/
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/*=============== BUTTONS ===============*/
.button {
  display: inline-block;
  background-color: var(--main);    
  color: var(--container-color);
  padding: 1rem 4rem;
  font-weight: var(--font-medium);
  transition: var(--transitions) ease-in box-shadow;
  border-radius: 100px;
}

.button:hover {
  background-color: var(--accent-dark);
  box-shadow:6px 6px 0px  var(--accent);
}


.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 3.55rem;
  }

  .button__icon {
    width: 22px;
    height: 22px;
  }
.section__title,
.section__subtitle {
  text-align: center;
}
}

/* For medium devices */
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 var(--header-height) 0;
  }

  .section {
    padding: 2rem 0 4rem;
  }

  .section__subtitle {
    margin-bottom: var(--mb-3);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 4.25rem;
  }

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
