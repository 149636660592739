.contact__container {
grid-template-columns: 1fr;
justify-content: flex-start;
column-gap: 4rem;
padding-bottom: 3rem;
}
.contact__title {
text-align: center;
font-size: var(--h3-font-size);
font-weight: var(--font-medium);
margin-bottom: var(--mb-1-5);
}
.contact__subtitle {
display: block;
width: 100%;
text-align: center;
margin-bottom: 1rem;
}
.contact__info {
display: grid;
row-gap: 1rem;
grid-template-columns: 1fr;
justify-content: center;
}
.contact__card {
background-color: var(--container-color);
border: 1px solid rgba(0,0,0,0.1);
padding: 1rem;
text-align: center;
}
.contact__card-icon {
font-size: 2rem;
color: var(--title-color);
margin-bottom: var(--mb-0-25);
}
.contact__card-title,
.contact__card-data {
font-size: var(--small-font-size);
}
.contact__card-title {
font-weight: var(--font-medium);
}
.contact__card-data {
display: block;
margin-bottom: var(--mb-0-75);
}
.contact__button {
color: var(--text-color);
font-size: var(--small-font-size);
display: inline-flex;
align-items: center;
justify-content: center;
column-gap: 0.25rem;
}
.contact__button-icon {
font-size: 1rem;
transition: 0.3s;
}
.contact__button:hover .contact__button-icon {
transform: translateX(0.35rem);
}

.contact__form {
width: 100% !important;
margin: 0 auto;
text-align: center;
}
.contact__form-div {
position: relative;
margin-bottom: var(--mb-2);
height: 3rem;
}
.contact__form-input {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border: 1px solid rgba(0,0,0,0.3);
background-color: none;
color: var(--text-color);
outline: none;
padding:1.5rem;
z-index: 1;
}
.contact__form-input::placeholder{
font-size: var(--smaller-font-size);
color: rgba(0,0,0,0.3);
}

.contact__form-tag {
position: absolute;
top: -0.75rem;
left: 1.25rem;
font-size: var(--smaller-font-size);
padding: 0.25rem;
background-color: var(--body-color);
z-index: 9;
}
.contact__form-textarea {
height: 8rem;
}
.contact__form-textarea textarea {
width: 100% !important;
resize: none;
}
.alert__wrapper {
padding: 2px 1rem 4px 0;
}
.message_alert{
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: rgb(127, 255, 185);
  border-radius: 8px;
}
@media screen and (min-width: 576px ){
  .contact__info {
  grid-template-columns: 600px;
  }
  .contact__form {
  width: 600px !important;
  }
}

@media screen and (min-width: 992px ){
  .contact__container {
    grid-template-columns: repeat(2, max-content);
  }
  .contact__info {
  grid-template-columns: 300px;
  }
  .alert__wrapper {
  padding: 2px 5rem 4px 0;
  }
  .message_alert{
  text-align: left;
  }
}