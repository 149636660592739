.header {
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: var(--z-fixed);
background-color: transparent;
transition: var(--transitions-head);
}
.nav{
height: calc(var(--header-height) + 1.5rem);
display: flex;
justify-content: space-between;
align-items: center;
column-gap: 1rem;
transition: var(--transitions-head);
}
.nav__logo{
  position: relative;
  /* background: url(../../assets/logo.png); */
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}
.logo__container .em {
  fill: white;
}
.logo__container .circle {
fill: var(--main);
}
.logo__content{
position: absolute;
top: 3px;
right: -12px;
font-size: .45rem;
padding: 1px 4px;
border: .5px solid rgba(255, 255, 255, 0.2);
border-radius: 50%;
color: var(--container-color);
}
.nav__logo,
.nav__toggle {
color: var(--main);
font-weight: var(--font-medium);
}
.nav__list{
  display: flex;
column-gap: 2rem;
}
.nav__link {
display: flex;
flex-direction: column;
align-items: center;
line-height: 1.2;
font-size: var(--smaller-font-size);
color: var(--white-text);
font-weight: var(--font-medium);
transition: var(--transitions);
cursor: pointer;
}
.nav__icon{
font-size: var(--h3-font-size);
}
.nav__close,
.nav__toggle{
display: none;
}
/* active link and hover */
.active__link{
color: var(--accent-light);
transition: var(--transitions);
}
.nav__link:hover{
color: var(--accent);
}

/* sticky header  */
.sticky__head {
background-color: var(--container-color);
box-shadow:0 1px 5px rgba(0,0,0,0.15);
}
.sticky__head .nav {
height: calc(var(--header-height) + 1rem);
}
.sticky__head .nav__link {
  color: var(--text-color);
}
/* .sticky__head .logo__container .em {
fill: var(--container-color);

}
.sticky__head .logo__container .circle {
  fill: var(--title-color);
} */
.sticky__head .logo__content{
border: .5px solid rgba(45, 45, 45, 0.2);
color: var(--text-color);
}
.sticky__head .active__link{
color: var(--accent);
transition: var(--transitions);
}
.sticky__head .nav__link:hover{
color: var(--accent-dark);
}
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 768px) {
  .header{
    top: initial;
    bottom: 0;
    background-color: var(--container-color);
    box-shadow:0 1px 5px rgba(0,0,0,0.15);
  }
  .nav{
  height: var(--header-height);
  }
 .nav__link {
  color: var(--text-color);
}
  .logo__container .em {
  fill: var(--container-color);

  }
  .logo__container .circle {
    fill: var(--main);
  }
  .logo__content{
  border: .5px solid rgba(45, 45, 45, 0.2);
  color: var(--text-color);
  }
  .nav__menu {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  background-color: var(--body-color);
  padding: 2rem 1.5rem 4rem;
  box-shadow: 0 -1px 4px rgba(0,0,0,0.15);
  border-radius: 1.5rem 1.5rem 0 0;
  transition: var(--transitions);
  }
  /* show menu after toggler */
  .show__menu{
    bottom: 0;
  }
  .nav__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap:2rem;
  }
.nav__icon{
  font-size: 1.2rem;
}
.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: .5rem;
  font-size: 1.6rem;
  cursor: pointer;
  color: var(--title-color-dark);
}
.nav__toggle{
  font-size: 1.6rem;
  cursor: pointer;
}
  .nav__close,
  .nav__toggle{
    display: block;
  }
}

/*==== for small devices ===*/
@media screen and (max-width: 500px){
.nav__logo{
  width: 35px;
  height: 35px;
}
}
@media screen and (max-width: 350px){
  .nav__menu{
    padding: 2rem 0.25rem 4rem;
  }
  .nav__list {
    column-gap: 0;
  }
}

.flag {
  position: relative;
  padding:0 5px;
  text-align: center;
}
.flag::after{
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    right: 0;
    top: 5px;


}
.flag:hover .flag-down {
  opacity: 1;
  transform: translateY(0);
    transition: 350ms ease all;
}

.flag-down {
opacity: 0;
transform: translateY(-50%);
position: absolute;
bottom: -58px;
left: 10px;
padding:12px 5px 5px 0;
}