.skills__container {
  grid-template-columns: repeat(1, 1fr);
  row-gap: 2rem;
  justify-content: center;
}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0,0,0,0.1);
  padding: 1.5rem;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}
.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}
.skills__data {
  display: flex;
  column-gap: 0.5rem;
}
.skills__data i {
  font-size: 1.4rem;
  color: var(--accent);
}
.skills .bx-badge-check {
  font-size: 1rem;
  color: var(--accent);
}

@media screen and (min-width: 576px ){
  .skills__container{
   grid-template-columns: repeat(2, 340px);
  }
  .skills__content {
    padding: 2rem 4rem;
  }
}

@media screen and (min-width: 992px ){
  .skills__container{
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
}