.footer {
  background-color: var(--container-color);
  border-top: 1px solid rgba(0,0,0,0.1);
}
.footer__container{
padding: 2rem 0 1rem;
}
.footer__link {
  color: var(--title-color);
  font-size: var(--small-font-size);
}

.footer__title {
text-align: center;
color: rgba(0,0,0,0.05);
margin-bottom: var(--mb-2);
}

.footer__link:hover {
color: var(--title-color-dark);
}
.footer__list {
display: flex;
justify-content: center;
column-gap: 1rem;
margin-bottom: var(--mb-2);
flex-wrap: wrap;
}

.footer__social {
display: flex;
justify-content: center;
column-gap: 0.75rem;
margin-bottom: var(--mb-2);
}
.footer__social-link {
  color: var(--container-color);
  font-size: 1.25rem;
  padding: 0.4rem;
  background-color: var(--main);
  border-radius: 0.5rem;
  display: inline-flex;
}
.footer__social-link:hover {
background-color: var(--accent-dark);
}

.footer__copy {
display: block;
margin-top: 3.5rem;
color: var(--title-color);
text-align: center;
font-size: var(--smaller-font-size);
}